<header class="pt-4 -mt-4 z-[9999999]">
  <div class="max-w-5xl py-[15px]">
    <div class="flex justify-between items-center font-barlow text-[#171819]">
      <div class="mx-4">
        <a [routerLink]="['/']">
          <img class="w-32" src="/assets/images/logo.png" alt="Shopgrid" />
        </a>
      </div>
    </div>
  </div>
</header>
