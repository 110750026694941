import { environment } from 'src/environments/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
// [[Providers]]

// ]
// [[Interfaces do Componente]]
// ]
@Component({
  selector: 'app-www-header',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
})
export class WwwHeaderComponent implements OnInit, OnDestroy {
  /**
   * Base de uso em Component
   */
  public environment = environment;
  messageProcess = false;
  messageIsError: any;
  profile: any;
  currentLanguage: string;

  favicon: HTMLLinkElement = document.querySelector('#app_icon');

  actualUrl: any;

  constructor(
    /**
     * Serviços da Aplicação
     */
    private router: Router
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.burgerMenuIsExpanded = false;
      }
    });
  }

  /**
   * on init
   */
  ngOnInit() {
    this.currentLanguage = 'Português';
  }

  ngOnDestroy(): void {}

  changeSiteLanguage(data: string) {
    this.currentLanguage = data;
  }

  burgerMenuIsExpanded: boolean = false;
  /**
   * Burgers menu
   */
  burgerMenu() {
    this.burgerMenuIsExpanded = !this.burgerMenuIsExpanded;
  }
}
