import { environment } from 'src/environments/environment';
import { Component, AfterViewInit, ViewChild, ElementRef, HostListener, Input, ComponentFactoryResolver } from '@angular/core';
import { fromEvent } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
// [[Providers]]

// ]
// [[Interfaces do Componente]]
// ]
@Component({
  selector: 'app-www-landing-header',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
})
export class WwwLandingHeaderComponent {
  /**
   * Base de uso em Component
   */
  public environment = environment;
  messageProcess = false;
  messageIsError: any;
  profile: any;

  favicon: HTMLLinkElement = document.querySelector('#app_icon');

  actualUrl: any;

  constructor(
    /**
     * Serviços da Aplicação
     */
    private router: Router
    /**
     * Services
     */
  ) {}

  /**
   * on init
   */
  ngOnInit() {}
}
