<header>
  <nav class="bg-white border-gray-200 px-4 lg:px-6 py-2.5">
    <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
      <a [routerLink]="['/']" class="flex items-center">
        <img src="/assets/images/logo.png" class="mr-3 h-6 sm:h-9" alt="Shopgrid" />
      </a>
      <div class="flex items-center lg:order-2">
        <a href="{{environment.app_url}}" class="text-gray-800 hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 focus:outline-none">Acessar</a>
        <a [routerLink]="['/pricing/platform']" class="text-white bg-fuchsia-700 hover:bg-fuchsia-800 focus:ring-4 focus:ring-fuchsia-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 focus:outline-none">Preços</a>
      </div>
    </div>
  </nav>
</header>
